@import "@/assets/scss/style.scss";

.c-settings__list-fit {
    width: 100%;
}

.download {
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 2px 4px;
    background: transparent;

    &:hover {
        background: #e6f7ff;
        cursor: pointer;
    }
}

::v-deep .ant-list-bordered {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-item {
    cursor: pointer;

    .c-settings__list-text {
        word-break: break-word;
    }
}

.custom-search {
    min-height: 451px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
