@import "@/assets/scss/style.scss";

.documents-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 2% 5%;

        @media (max-width: 850px) {
            flex-direction: column;
        }

        &__left {
            width: 280px;
            min-width: 280px;
            display: flex;
            flex-direction: column;
            margin-right: 130px;

            @media (max-width: 1200px) {
                margin-right: 50px;
            }

            @media (max-width: 850px) {
                margin: 0;
                width: 100%;
            }

            .directory-list {
                margin-top: 24px;
                display: flex;
                width: 100%;
                flex-direction: column;
                padding: 0;

                li {
                    min-height: 44px;
                    height: fit-content;
                    border-radius: 4px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 12px;
                    list-style: none;
                    cursor: pointer;

                    &:hover {
                        background: #eef8fd;
                    }

                    &.active {
                        background: #e6f7ff;
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 45px;
        }
    }

    .path-list {
        margin-top: 36px;
        border: 1px solid #d9d9d9;
        border-bottom: none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12px 24px;
        list-style: none;
    }

    .arrow-right {
        margin: 0 8px;
    }

    .back-btn {
        cursor: pointer;
    }

    .search-wrapper {
        margin-bottom: 36px;
    }
}

.ant-checkbox-wrapper {
    padding: 4px 2px;
}
.c-table-search-bar {
    margin-bottom: 8px;

    &__filter {
        cursor: pointer;
    }

    &__filter-icon {
        margin-right: 10px;
    }

    &__filter-title {
        color: $black;
    }
    & /deep/ .ant-input-affix-wrapper .ant-input-suffix {
        // width: 5%;
        right: 6px;
        // top: 16px;
        height: 80%;
        .ant-btn {
            height: 100%;
            background: $grey-three;
            border: none;
        }
        .ant-input-search-icon {
            display: none;
        }
    }
    & /deep/ .ant-input-affix-wrapper .ant-input-prefix {
        opacity: 0.65;
    }
    & /deep/ .ant-input-search {
        width: 98%;
        margin-right: auto;
    }
    & /deep/ .ant-input-group-addon:last-child {
        padding: 4px 11px;
        border: 1px solid $grey-1;
        border-radius: 2px;
        width: 5%;
        min-width: 80px;
    }
    transition: padding-right 0.3s ease-in-out;
}
.are-filters-visible {
    transition: padding-right 0.3s ease-in-out;
    padding-right: 275px;
}
.active-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .active-filter {
        background-color: $grey-4;
        margin-right: 10px;
        margin-bottom: 8px;
        padding: 2px 8px;
        display: block;
        height: auto;
        &.ant-btn {
            background-color: white;
        }
    }
}

::v-deep .c-table-search-bar .ant-input-affix-wrapper .ant-input-suffix .ant-btn {
    height: 100%;
    background: #f5f5f5;
    border: none;
}

::v-deep .ant-input-suffix .anticon {
    display: none;
}

::v-deep .ant-input-suffix .ant-btn {
    margin: 12px 0;
    background: #f5f5f5;
    border-radius: 2px;
    border: none;
}

::v-deep .c-table-search-bar {
    height: 40px;

    input {
        width: 100%;
        height: 100%;
    }
}

::v-deep .ant-input-suffix {
    right: 6px;
}

::v-deep .ant-select-selection__rendered {
    height: 40px;
    display: flex;
    align-items: center;
}

::v-deep .ant-select-selection {
    height: 40px;
}
